
const styles = {
    Blog:{
        position:"relative",
        color: "green"
    },
    commentForm:{
        border: "2px solid black",
        padding: "20px",
        position:"absolute",
        width: "50vw",
        left: "25vw",
        right: "25vw",
        top: "25vh",
        height: "50vh",
        bottom: "25vh",
    }
}

export default styles;